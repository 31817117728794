@font-face {
  font-family: "good-times";
  src: local("good-times"), url("GoodTimesLt-Regular.woff") format("woff");
}

@font-face {
  font-family: "good-times-bold";
  src: local("good-times-bold"), url("GoodTimesRg-Bold.woff") format("woff");
}

@font-face {
  font-family: "good-times-italic";
  src: local("good-times-italic"),
    url("GoodTimesRg-BoldItalic.woff") format("woff");
}

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600&family=Hahmlet:wght@100;200;300;400;500;600&family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,300;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cabin:wght@400;500&family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600&family=Hahmlet:wght@100;200;300;400;500;600&family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,300;1,400;1,700&display=swap");
