@import "assets/fonts/fonts.scss";

$primary: #c8fc21;
$secondary: #303945;
$orange: #fd953a;
$grey: #d9d9d9;
$darkgreen: #47590a;

html {
  // font-size: 62.5%; // 1rem = 10px
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
}

body {
  min-width: 300px;
  font-size: 16px !important;
  // font-family: "Poppins", sans-serif;
  font-family: "good-times", sans-serif;
  font-style: normal;
  font-weight: normal;
  overflow-x: hidden !important;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.text {
  font-family: "Hahmlet", serif;
  // font-family: "DM Sans", sans-serif;
  // font-family: "Lato", sans-serif;
}

a,
a:active,
a:link,
a:visited {
  text-decoration: none !important;
}

b,
.text-bold {
  font-family: "good-times-bold", sans-serif;
  font-weight: 500;
}

i,
.text-bold-italic {
  font-family: "good-times-italic", sans-serif;
  font-weight: 500;
}

.link {
  color: 0e80E6 !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0 !important;
  font-weight: 600;
}

.row,
.col {
  margin: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.bg-primary {
  background: $primary !important;
}

.bg-secondary {
  background: $secondary !important;
}

.bg-orange {
  background: $orange !important;
}

.bg-grey {
  background: $grey !important;
}

.bg-darkgreen {
  background: $darkgreen !important;
}

.border-primary {
  border-color: $primary !important;
}

.border-secondary {
  border-color: $secondary !important;
}

.border-orange {
  border-color: $orange !important;
}

.border-grey {
  border-color: $grey !important;
}

.text-primary {
  color: $primary !important;
}

.text-secondary {
  color: $secondary !important;
}

.text-orange {
  color: $orange !important;
}

.text-grey {
  color: $grey !important;
}

// ----- showcase -----
// .showcase {
//   width: "100vw";
//   height: "100vh";
//   position: relative;
//   color: white;
//   text-align: center;
// }

// .showcase img {
//   width: "100vw";
//   height: "100vh";
//   position: absolute;
//   top: 0;
//   left: 0;
//   z-index: 99;
// }

// .showcase .overlay {
//   width: 100%;
//   height: "100vh";
//   background-color: rgba(0, 0, 0, 0.7);
//   position: absolute;
//   top: 0;
//   left: 0;
//   z-index: 999;
// }

.showcase {
  width: 100vw;
  height: 100vh;
}

.showcase img {
  width: 100vw;
  height: 100vh;
  z-index: 99;
  object-fit: cover;
}

.showcase .overlay-container {
  width: 100vw;
  height: 100vh;
  z-index: 999;
}

.overlay-0 {
  background-color: rgba(0, 0, 0, 0);
}

.overlay {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

// ----- paragraph overflow -----
.max-lines-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.max-lines-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.max-lines-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

// ----- scrollbar-none -----
.scrollbar-none::-webkit-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.scrollbar-none::-webkit-scrollbar {
  display: none;
}

// ----- no focus -----
.focus-none:focus,
.focus-none:visited {
  // border: none !important;
  outline: none !important;
  box-shadow: none !important;
  color: currentColor;
}

// ----- input focus -----
input:focus,
.form-check-input:focus,
.form-select:focus,
.form-control:focus {
  border-color: $grey !important;
  box-shadow: none !important;
  outline: none !important;
}

.contact-form-input {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgba(0, 0, 0, 1) inset !important;
  outline: none;
  border-width: 0px;
}

input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}

// ----- modal -----
.custom-modal,
.modal {
  background: transparent !important;
}

.dotted-line-hor {
  border-top: 3px dotted $primary;
}

.dotted-line-vertical {
  border-left: 3px dotted $primary;
}

.modal-content {
  display: flex !important;
  flex-direction: column;
}

// --------- font sizes ---------
h1 {
  font-size: 44px !important;
}

h2 {
  font-size: 32px !important;
}

h3 {
  font-size: 24px !important;
}

h4 {
  font-size: 18px !important;
}

p,
li,
.form-check-label {
  font-size: 18px;
}

.ppheaders {
  font-size: 18px !important;
}

.newsTitle {
  font-size: 18px !important;
}

.text-small {
  font-size: 14px;
}

.text-extra-small {
  font-size: 12px;
}

@media only screen and (max-width: 992px) {
  h1 {
    font-size: 44px !important;
  }

  h2 {
    font-size: 32px !important;
  }

  h3 {
    font-size: 24px !important;
  }

  h4 {
    font-size: 18px !important;
  }

  p,
  li,
  .form-check-label {
    font-size: 18px !important;
  }

  .ppheaders {
    font-size: 18px !important;
  }

  .newsTitle {
    font-size: 20px !important;
  }

  .text-small {
    font-size: 14px !important;
  }

  .text-extra-small {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 36px !important;
  }

  h2 {
    font-size: 28px !important;
  }

  h3 {
    font-size: 24px !important;
  }

  h4 {
    font-size: 20px !important;
  }

  p,
  li,
  .form-check-label {
    font-size: 16px !important;
  }

  .ppheaders {
    font-size: 16px !important;
  }

  .newsTitle {
    font-size: 18px !important;
  }

  .text-small {
    font-size: 12px !important;
  }

  .text-extra-small {
    font-size: 10px !important;
  }
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: 30px !important;
  }

  h2 {
    font-size: 24px !important;
  }

  h3 {
    font-size: 20px !important;
  }

  h4 {
    font-size: 16px !important;
  }

  p,
  li,
  .form-check-label {
    font-size: 16px !important;
  }

  .text-small {
    font-size: 12px !important;
  }

  .text-extra-small {
    font-size: 10px !important;
  }
}

@media only screen and (max-width: 400px) {
  h1 {
    font-size: 26px !important;
  }

  h2 {
    font-size: 20px !important;
  }

  h3 {
    font-size: 18px !important;
  }

  h4 {
    font-size: 14px !important;
  }

  p,
  li,
  .form-check-label {
    font-size: 14px !important;
  }

  .text-small {
    font-size: 10px !important;
  }

  .text-extra-small {
    font-size: 8px !important;
  }
}

// ----- action buttons text -----
.action-button-text {
  font-size: 12px;
}

.action-button-text-cabin {
  font-size: 18px;
  font-family: "Cabin", sans-serif !important;
  font-weight: 400;
  letter-spacing: 1.6;
}

.qr-code-text {
  font-size: 8px;
}

@media only screen and (max-width: 1400px) {
  .action-button-text {
    font-size: 10px !important;
  }

  .action-button-text-cabin {
    font-size: 12px !important;
  }

  .qr-code-text {
    font-size: 7px !important;
  }
}

@media only screen and (max-width: 1200px) {
  .qr-code-text {
    font-size: 6px !important;
  }
}

@media only screen and (max-width: 992px) {
  .action-button-text {
    font-size: 8px !important;
  }

  .action-button-text-cabin {
    font-size: 12px !important;
  }

  .qr-code-text {
    font-size: 8px !important;
  }
}

@media only screen and (max-width: 768px) {
  .action-button-text {
    font-size: 10px !important;
  }

  .action-button-text-cabin {
    font-size: 12px !important;
  }

  .qr-code-text {
    font-size: 7px !important;
  }
}

@media only screen and (max-width: 600px) {
  .action-button-text {
    font-size: 10px !important;
  }

  .action-button-text-cabin {
    font-size: 12px !important;
  }

  .qr-code-text {
    font-size: 6px !important;
  }
}

@media only screen and (max-width: 540px) {
  .action-button-text {
    font-size: 10px !important;
  }

  .action-button-text-cabin {
    font-size: 12px !important;
  }

  .qr-code-text {
    font-size: 6px !important;
  }

  .newsTitle {
    font-size: 16px !important;
  }
}

.timeline-card-title {
  font-size: 12px;
  font-weight: 500;
}

.timeline-card-subtitle {
  font-size: 12px !important;
}

.timeline-card-content {
  font-size: 10px !important;
}

@media only screen and (max-width: 600px) {
  .timeline-card-title {
    font-size: 12px !important;
    font-weight: 500 !important;
  }

  .timeline-card-subtitle {
    font-size: 9px !important;
  }

  .timeline-card-content {
    font-size: 10px !important;
  }
}

@media only screen and (min-width: 600px) {
  .timeline-card-title {
    font-size: 12px !important;
    font-weight: 500 !important;
  }

  .timeline-card-subtitle {
    font-size: 9px !important;
  }

  .timeline-card-content {
    font-size: 10px !important;
  }
}

@media only screen and (min-width: 768px) {
  .timeline-card-title {
    font-size: 14px !important;
    font-weight: 500 !important;
  }

  .timeline-card-subtitle {
    font-size: 11px !important;
  }

  .timeline-card-content {
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 992px) {
  .timeline-card-title {
    font-size: 12px !important;
    font-weight: 500 !important;
  }

  .timeline-card-subtitle {
    font-size: 9px !important;
  }

  .timeline-card-content {
    font-size: 10px !important;
  }
}

@media only screen and (min-width: 1200px) {
  .timeline-card-title {
    font-size: 12px !important;
    font-weight: 500 !important;
  }

  .timeline-card-subtitle {
    font-size: 9px !important;
  }

  .timeline-card-content {
    font-size: 10px !important;
  }
}

@media only screen and (min-width: 1400px) {
  .timeline-card-title {
    font-size: 14px !important;
    font-weight: 500 !important;
  }

  .timeline-card-subtitle {
    font-size: 11px !important;
  }

  .timeline-card-content {
    font-size: 12px !important;
  }
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.content-wrapper {
  // width: 100%;
  padding: 0 10px;
  margin-right: auto;
  margin-left: auto;
}

@media only screen and (max-width: 600px) {
  .content-wrapper {
    // max-width: 584px;
    padding: 0 10px;
  }
}

@media only screen and (min-width: 600px) {
  .content-wrapper {
    // max-width: 572px;
    padding: 0 16px;
  }
}

@media only screen and (min-width: 768px) {
  .content-wrapper {
    // max-width: 752px;
    padding: 0 24px;
  }
}

@media only screen and (min-width: 992px) {
  .content-wrapper {
    // max-width: 964px;
    padding: 0 32px;
  }
}

@media only screen and (min-width: 1200px) {
  .content-wrapper {
    // max-width: 1160px;
    padding: 0 44px;
  }
}

@media only screen and (min-width: 1400px) {
  .content-wrapper {
    // max-width: 1320px;
    padding: 0 66px;
  }
}

// ---------- carousel ----------
.carousel-item {
  transition: transform 0.5s;
}

.carousel-item.active {
  transform: translateX(0);
}

.carousel-item:not(.active) {
  transform: translateX(100%);
}

.wallpapers-border-gradient-button {
  border: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
  border-image-source: linear-gradient(to right, #fff, #727272);
}
